













































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CHANGE_CONSERVATEE_ADDRESS } from '../../../store/actions';
import { ChangeAddress, ConservatorshipDetails } from '../../../types';
import { ApiResponse } from '@/components/types';

const Conservatorship = namespace('conservatorship');

@Component({})
export default class ChangeAddressDialog extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Conservatorship.Action(CHANGE_CONSERVATEE_ADDRESS) changeAddress!: (params: ChangeAddress) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: ChangeAddress = {
    conservatee_id: this.conservatorship.id,
    name: this.conservatorship.conservatee.address.name,
    additional: this.conservatorship.conservatee.address.additional,
    street: this.conservatorship.conservatee.address.street,
    city: this.conservatorship.conservatee.address.city,
    zip_code: this.conservatorship.conservatee.address.zipCode,
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        conservatee_id: this.conservatorship.id,
        name: this.conservatorship.conservatee.address.name,
        additional: this.conservatorship.conservatee.address.additional,
        street: this.conservatorship.conservatee.address.street,
        city: this.conservatorship.conservatee.address.city,
        zip_code: this.conservatorship.conservatee.address.zipCode,
      };

      return;
    }

    setTimeout(() => {
      // @ts-ignore
      this.$refs.form.reset();
      this.error = null;
    }, 200);
  }


  async save() {
    this.error = null;

    return this.changeAddress(this.value);
  }
}
